import React, {FC} from 'react';
import './Home.css';
import './Pages.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import strings from '../resources/strings';
import Button from 'react-bootstrap/Button';

const Home:FC<{}> = () => {

  return (
    <div className="Page-content">
      <Container>
        <Row>
          <Col className="Page-header" sm={12} xs={12}>
            Media
          </Col>
        </Row>
      <Row>
        <Col xxl={3} lg={4} md={12} sm={12} xs={12}>
          <Card className="Page-card">
            <Card.Img variant="top" src="media-logo.png" />
            <Card.Body>
              <Card.Title className="text-center">Shared Media Libraries</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col xxl={9} lg={8} md={12} sm={12} xs={12}>
        <Card className="Page-card">
            <Card.Header className='fw-bold fs-5 text-center'>
              For the select few - use the links below to access my shared media libraries
            </Card.Header>
            <Card.Body className='fs-5'>
              <Card.Text>
                To jump straight in to the Plex Media Server, head on over to <Button variant="secondary" size='sm' href="http://media.chrisdial.net:32400/web/index.html">Plex</Button>
              </Card.Text>
              <Card.Text>
                And to make requests for new media, you can use <Button variant="secondary" size='sm' href="http://media.chrisdial.net:5055/">Dial Media Requests</Button>
              </Card.Text>
            </Card.Body>


          </Card>


        </Col>
      </Row>
      </Container>
    </div>
  );
}

export default Home;
