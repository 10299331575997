import {Container, Row, Col} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import strings from '../resources/strings';
import React, {FC} from 'react';
import Carousel from 'react-bootstrap/Carousel';

const Family: FC<{}> = () => {
    return (
        <div className="Page-content">
            <Container>
                <Row>
                    <Col className="Page-header" sm={12} xs={12}>
                        {strings.family.title}
                    </Col>
                    
                </Row>
                <Row>
                    <Col md={12} sm={12} xs={12}>
                    {/*    <Card className="Page-card">
                            <Card.Img variant="top" src="dialfamily_web.jpg" />
                        </Card> */}
<Card className="Page-card">
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="dialfamily_1.jpg?text=First slide&bg=373940"
                                    alt="Family Photos 2022"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="dialfamily_2.jpg?text=First slide&bg=373940"
                                    alt="Christmas 2020"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="dialfamily_3.jpg?text=First slide&bg=373940"
                                    alt="Extended Family"
                                />
                            </Carousel.Item>


                        </Carousel>
                        </Card>
                    </Col> 

                    <Col md={12} sm={12} xs={12}>
                        <Card className="Page-card">
                            <Card.Body>
                            <Card.Text>
                                My family is a very important part of who I am. I have a beautiful wife, Traci, who I have been with since 2004.
                                Together we have created an amazing family, with 3 boys, from oldest to youngest: Taylor, Grant and Harrison.
                            </Card.Text>
                            </Card.Body>
                        </Card>


                    </Col>

                </Row>

            </Container>
        </div>
    );
}

export default Family;