import { configureStore, combineReducers } from '@reduxjs/toolkit'
import userReducer from "./userStoreSlice";

// Try using this to figure this crap out: https://react-redux.js.org/using-react-redux/usage-with-typescript

const rootReducer = combineReducers({ userReducer });

const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof rootReducer>;

export default store;