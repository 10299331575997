const strings = {
    blog: {
        title: "My Blog",
        posted_on: "Posted On",
        posted_by: "By"
    },
    crypto: {
        title: "Cryptocurrency"
    },
    family: {
        title: "Family"
    },
    home: {
        title: "About Me"
    },
    media: {
        title: "Media"
    }
}

export default strings;