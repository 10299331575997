import React, { FormEvent, useEffect, useState, FC } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

type SubmitBlogProps = {
  isOpen: boolean;
  hydratePosts: () => void;
}

const SubmitBlog:FC<SubmitBlogProps> = ({isOpen, hydratePosts}) => {
  
  const [show, setShow] = useState(isOpen);

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget),
    formDataObj = Object.fromEntries(formData.entries());
    const jwt = localStorage.getItem('user_jwt');

    fetch(window.location.protocol + '//' + window.location.hostname + ':3000/addblog', {
        method: "POST",
        body: JSON.stringify(formDataObj),
        headers: new Headers({
          'Authorization': 'Bearer ' + jwt,
          'Content-Type': 'application/json'
        })
    })
    .then((response) => response.json())
    .then((data) => {
       hydratePosts();
    })
    .catch((err) => {
       console.log(err.message);
    });

    setShow(false);
  }
  const handleClose = () => {
    setShow(false);
  }

  const handleShow = () => setShow(true);

  useEffect(() => {
       setShow(isOpen);
  }, [isOpen]);

  return (
    <>
      <Button className="d-flex" variant="primary" onClick={handleShow}>
        Submit Blog
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Submit New Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSave}>
          <Form.Group className="mb-3" controlId="title">
                <Form.Label>Post Title</Form.Label>
                <Form.Control type="text" placeholder="Title of Post" name="title"/>
            </Form.Group>

            
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Blog Content</Form.Label>
              <Form.Control as="textarea" rows={3} name="blogtext"/>
            </Form.Group>
            <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button className="ms-2" variant="primary" type="submit">
            Save Changes
          </Button>

          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SubmitBlog;