import {Container, Row, Col} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import React, {FC} from 'react';
import strings from "../resources/strings";

const Crypto:FC<{}> = () => {
    return (
        <div className="Page-content">
            <Container>
                <Row>
                    <Col className="Page-header" sm={12} xs={12}>
                        {strings.crypto.title}
                    </Col>
                </Row>
                <Row>
                    <Col xxl={3} lg={4} md={12} sm={12} xs={12}>
                        <Card className="Page-card">
                            <Card.Img variant="top" src="crypto.png" />
                        </Card>
                    </Col>

                    <Col xxl={9} lg={8} md={12} sm={12} xs={12}>
                        <Card className="Page-card">
                            <Card.Body>
                            <Card.Text>
                                My first experience with cryptocurrency was way back in 2010. I learned about bitcoin and decided, like many others, to check it out.
                                I mined about 9BTC and put my crypto in <a rel="noreferrer" target="_blank" href="https://en.wikipedia.org/wiki/Mt._Gox">Mt Gox</a>, and like many others, my BTC disappeared without a trace.
                            </Card.Text>
                            <Card.Text>
                                I didn't seriously look at cryptocurrency again until 2021, when I started using my gaming machines to mine ETH for a small profit.
                                Then, in the fall of 2021, I started mining <a rel="noreferrer" target="_blank" href="https://www.dynamocoin.org/">Dynamo</a>. This coin was, at the time, much more profitable to mine than 
                                any other coin I had found, although it was a very small coin. During this time I decided to try my hand at writing some code related
                                to crypto.
                            </Card.Text>
                            <Card.Text>
                                I then created a site, that could be used in conjunction with a utility also written by myself, that would allow miners of Dynamo to 
                                monitor their cryptominers. This site was partly an exploration of cryptocurrency, but also my first real foray into using <a rel="noreferrer" target="_blank" href="https://en.wikipedia.org/wiki/Go_(programming_language)">Go</a> for a serious sizable project.
                                This site is still operational now, and can be found at <a rel="noreferrer" target="_blank" href="https://www.dmo-monitor.com/">www.dmo-monitor.com</a>.
                            </Card.Text>
                            </Card.Body>
                        </Card>


                    </Col>

                </Row>
            </Container>
        </div>
    );
}

export default Crypto;