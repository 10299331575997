import React, {FC} from 'react';
import './Home.css';
import './Pages.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import strings from '../resources/strings';

const Home:FC<{}> = () => {

  return (
    <div className="Page-content">
      <Container>
        <Row>
          <Col className="Page-header" sm={12} xs={12}>
            {strings.home.title}
          </Col>
        </Row>
      <Row>
        <Col xxl={3} lg={4} md={12} sm={12} xs={12}>
          <Card className="Page-card">
            <Card.Img variant="top" src="chris_thumbsup.jpg" />
            <Card.Body>
              <Card.Title className="text-center">Senior Full Stack Engineer</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col xxl={9} lg={8} md={12} sm={12} xs={12}>
        <Card className="Page-card">
            <Card.Body>
              <Card.Text>
                I am a full stack developer with a passion for all things code related.
                  As a teenager I cut my teeth writing assembly and C related to games and the primitive 3d graphics of the early 90s. 
              </Card.Text>
              <Card.Text>
                  One of the first apps I ever wrote was <b>Chris Dial's SVGA Bench</b>, featured on sites like <a rel="noreferrer" target="_blank" href="https://www.anandtech.com/show/37">AnandTech</a> in 1997. 
              </Card.Text>
              <Card.Text>
                  From there I moved on to work as a lead developer for a small startup in 2001 known as <a rel="noreferrer" target="_blank" href="https://web.archive.org/web/20010720084746/http://www.vision-games.com/">Vision Games</a> that was developing a game for the Xbox known as <a rel="noreferrer" target="_blank" href="https://www.xboxaddict.com/news/988/Xavier-Fox-Interview.html">Xavier Fox</a>. Unfortunately, although we presented our game at E3, we were unable to secure funding to bring our game to fruition.
              </Card.Text>
              <Card.Text>
                  In 2008 I began my first real corporate office job as a web developer for <a rel="noreferrer" target="_blank" href="https://www.musiciansfriend.com">Musicians Friend</a>, where I stayed until the company headquarters moved to California in 2011.
              </Card.Text>
              <Card.Text>
                Immediately following that I joined the work-from-home culture, working for a e-commerce consulting company known as <a rel="noreferrer" target="_blank" href="https://web.archive.org/web/20110722045120/http://www.doubleprime.com/">Double Prime</a>, their primary client being the <a rel="noreferrer" target="_blank" href="https://www.elcompanies.com/">Estée Lauder Companies</a>.
                This is where I remained for the next 11 years, working my way up (and through a number of corporate mergers) from a junior developer to eventually become the Senior OmniChannel Architect there.
              </Card.Text>
              <Card.Text>
                For more professional information about me, please see my <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/christopher-dial-2b11758/">LinkedIn page</a>.
              </Card.Text>
              <Card.Text>
                And to see a bit of my (mostly out of date) code, you can look at <a rel="noreferrer" target="_blank" href="https://github.com/dialmaster/">Github</a>
              </Card.Text>
            </Card.Body>
            

          </Card>


        </Col>
      </Row>
      </Container>
    </div>
  );
}

export default Home;
