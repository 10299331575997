import {Container, Nav, Navbar, NavLink } from "react-bootstrap";
import {Link} from "react-router-dom";
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './appStore';
import { useDispatch } from 'react-redux';
import { logout } from './userStoreSlice';


function NavigationBar() {
    const loggedIn = useSelector((state: RootState) => state.userReducer.loggedIn);
    const dispatch = useDispatch();

    const doLogout = () => {
        localStorage.clear();
        dispatch(logout());
    }

    return (
        <>
        <Navbar className="shadow" fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Toggle className="ms-3" aria-controls="navbarScroll" data-bs-target="#navbarScroll" />
            <Navbar.Collapse id="navbarScroll" className="topNav">
                <Nav className="ms-1">
                    <Container className="pt-2">
                        <Navbar.Brand>Christopher Dial</Navbar.Brand>
                    </Container>
                    <NavLink className="m-auto" eventKey="1" as={Link} to="/">Home</NavLink>
                    <NavLink className="m-auto" eventKey="2" as={Link} to="/crypto">Crypto</NavLink>
                    <NavLink className="m-auto" eventKey="3" as={Link} to="/family">Family</NavLink>
                    <NavLink className="m-auto" eventKey="4" as={Link} to="/blog">Blog</NavLink>
                    <NavLink className="m-auto" eventKey="5" as={Link} to="/media">Media</NavLink>
                    { !loggedIn && (<NavLink className="m-auto" eventKey="6" as={Link} to="/login">Login/Register</NavLink>)}
                    { loggedIn && (<NavLink className="m-auto" eventKey="7" onClick={doLogout}>Logout</NavLink>)}
                </Nav>
            </Navbar.Collapse>
        </Navbar>

        <Navbar className="shadow" fixed="bottom" bg="dark" variant="dark">

            <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="#navbarScroll" />

            <Navbar.Collapse>

                <Nav className="m-auto">
                    <Navbar.Text>Contact Me: <a href="mailto:dial.chris@gmail.com">dial.chris@gmail.com</a></Navbar.Text>
                </Nav>
            </Navbar.Collapse>

        </Navbar>

    </>

    );
}

export default NavigationBar;