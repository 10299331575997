import Home from "./Pages/Home";
import Crypto from "./Pages/Crypto";
import Family from "./Pages/Family";
import Blog from "./Pages/Blog";
import Login from "./Pages/Login";
import Media from "./Pages/Media";
import NavigationBar from "./NavigationBar";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import React, {FC, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { login } from './userStoreSlice';



const App:FC<{}> = () => {
  const dispatch = useDispatch();

  const hydrateUser = (jwt: string) => {
    fetch('http://localhost:3000/getuser', {
      method: "GET",
      headers: new Headers({
        'Authorization': 'Bearer ' + jwt,
        'Content-Type': 'application/json'
      })
  })
  .then((response) => response.json())
  .then((data) => {
     dispatch(login( {userId: data.userId, userName: data.userName } ));
  })
  .catch((err) => {
     console.log(err.message);
  });


  }

  useEffect(() => {
    const jwt = localStorage.getItem('user_jwt');
    if (jwt) {
      hydrateUser(jwt);
    }

});


    return (
      <div className="App">
        <Router>
            <NavigationBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/crypto" element={<Crypto />} />
                <Route path="/family" element={<Family />} />
                <Route path="/blog" element={<Blog />} />)
                <Route path="/login" element={<Login />} />
                <Route path="/media" element={<Media />} />
            </Routes>




        </Router>
      </div>
    );
  }

  export default App;