import React, {useState, FC} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, ButtonGroup } from 'react-bootstrap';
import LoginRegisterForm  from '../Components/LoginRegisterForm';

const Login:FC<{}> = () => {
    const [showLogin, setShowLogin] = useState<boolean>(true);
    
    const setLoginState = (state: boolean) => {
        setShowLogin(state);
    };

    return (
        <div className="Page-content">
            <Container>
                <Row>
                    <Col className="Page-header" sm={12} xs={12}>
                        Login / Register
                    </Col>
                </Row>
            </Container>

            <Row className="my-3">
                <Col sm={{span: 6, offset: 3}}>
                    <ButtonGroup>
                        <Button variant={showLogin ? ("primary") : ("secondary")} onClick={() => setLoginState(true)}>Login</Button>
                        <Button variant={!showLogin ? ("primary") : ("secondary")} onClick={() => setLoginState(false)}>Register</Button>
                    </ButtonGroup>
                </Col>
            </Row>

            {showLogin && (
            <Row className="mx-3">
                <Col sm={{span: 8, offset: 2}}>
                    <LoginRegisterForm
                        title="Login"
                        emailAddressLabel='Email Address'
                        passwordLabel='Password'
                        submitLabel='Login'
                        submitEndpoint={window.location.protocol + '//' + window.location.hostname + ':3000/login'}
                        isLogin={showLogin} />
                </Col>
            </Row>
            )}

            {!showLogin && (

            <Row className="mx-3">
                <Col sm={{span: 8, offset: 2}}>
                <LoginRegisterForm 
                        title="Register"
                        emailAddressLabel='Email Address'
                        passwordLabel='Password'
                        submitLabel='Create Account'
                        submitEndpoint={window.location.protocol + '//' + window.location.hostname + ':3000/register'}
                        nameLabel='Enter Your Name'
                        isLogin={showLogin} />

                </Col>
            </Row>
            )}

        </div>
    );

}

export default Login;