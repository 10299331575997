import React, { useEffect, useState, FC } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

type InfoModalProps = {
    title: string;
    modalText?: string;
    closeButtonLabel: string;
    isOpen: boolean;
    onCloseModal: () => void;
}

const InfoModal: FC<InfoModalProps> = ({title, modalText, closeButtonLabel, isOpen, onCloseModal}) => {

    const [show, setShow] = useState(isOpen);

    const handleClose = () => {
      onCloseModal();
      setShow(false);
    }

    useEffect(() => {
        setShow(isOpen);
    }, [isOpen]);

    return (
        <>
          <Modal  centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {modalText && (
            <Modal.Body>
                {modalText}
            </Modal.Body>
            )}
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {closeButtonLabel}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );

}

export default InfoModal;