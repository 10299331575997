import { Card, Form, Button } from 'react-bootstrap';
import React, { FormEvent, FC, useState } from 'react';
import InfoModal from './InfoModal';
import { useDispatch } from 'react-redux';
import { login } from '../userStoreSlice';
import { useNavigate } from 'react-router-dom';

type LoginRegisterFormProps  = {
    title: string;
    emailAddressLabel: string;
    passwordLabel: string;
    submitLabel: string;
    submitEndpoint: string;
    isLogin: boolean;
    nameLabel?: string;
}

type ModalInfo = {
    showModal: boolean;
    modalTitle: string;
    modalText?: string;
}

const LoginRegisterForm: FC<LoginRegisterFormProps> = ({title, emailAddressLabel, passwordLabel, submitLabel, submitEndpoint, isLogin, nameLabel}) => {
    const dispatch = useDispatch();
    const [modalInfo, setModalInfo] = useState<ModalInfo>({showModal: false, modalText: '', modalTitle: ''});
    const [homeOnModalClose, setHomeOnModalClose] = useState(false);
    const navigate = useNavigate();

    const onCloseModal = () => {
        setModalInfo({showModal: false, modalText: modalInfo.modalText, modalTitle: modalInfo.modalTitle});
        if (homeOnModalClose) {
            navigate('/');
        }
    }

    const onSubmitLogin = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget),
              formDataObj = Object.fromEntries(formData.entries());

            fetch(submitEndpoint, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formDataObj)
            })
            .then((response) => response.json())
            .then((data) => {
               if (data.error) {
                setModalInfo({showModal: true, modalText: data.error, modalTitle: 'Error'});
               } else {
                localStorage.setItem('user_jwt', data.jwt);
                setHomeOnModalClose(true);
                dispatch(login( {userId: data.userId, userName: data.userName } ));

                if (isLogin) {
                    setModalInfo({showModal: true, modalTitle: 'Login Success'});
                } else {
                    //setModalInfo({showModal: true, modalText: 'User Created', modalTitle: 'Success'});
                    setModalInfo({showModal: true, modalTitle: 'User Created'});
                }
              }

            })
            .catch((err) => {
               console.log(err.message);
            });

    };

    return (
    <>
    <Card className="Page-card">
        <Card.Body>
        <Card.Title>{title}</Card.Title>

        <Form onSubmit={onSubmitLogin}>
            {!isLogin && (
            <Form.Group className="mb-3" controlId="name">
                <Form.Label>{nameLabel}</Form.Label>
                <Form.Control type="text" placeholder={nameLabel} name="name"/>
            </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="email">
                <Form.Label>{emailAddressLabel}</Form.Label>
                <Form.Control type="email" placeholder={emailAddressLabel} name="email" required/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="password">
                <Form.Label>{passwordLabel}</Form.Label>
                <Form.Control type="password" placeholder={passwordLabel} autoComplete="password" name="password" required/>
            </Form.Group>
            <Button variant="primary" type="submit">
                {submitLabel}
            </Button>
        </Form>
        </Card.Body>
    </Card>

    <InfoModal
                title={modalInfo.modalTitle}
                modalText={modalInfo.modalText}
                closeButtonLabel='Close'
                isOpen={modalInfo.showModal}
                onCloseModal={onCloseModal} />
    </>
    );
}

export default LoginRegisterForm;