import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loggedIn: false,
    jwt: '',
    userName: '',
    email: '',
    userId: 0,
  },
  reducers: {
    logout: (state) => {
      state.loggedIn = false;
      state.jwt = '';
      state.userName = '';
      state.email = '';
      state.userId = 0;
    },
    login: (state, action) => {
      state.userId = action.payload.userId;
      state.jwt = action.payload.jwt;
      state.userName = action.payload.userName;
      state.email = action.payload.email;
      state.loggedIn = true;
    },
  },
})

// Action creators are generated for each case reducer function
export const { logout, login } = userSlice.actions

export default userSlice.reducer