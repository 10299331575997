import React, {FC, useState, useEffect} from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import SubmitBlog from '../Components/SubmitBlog';
import strings from '../resources/strings';
import { useSelector } from 'react-redux';
import { RootState } from '../appStore';
import { useNavigate } from 'react-router-dom';

type User = {
    email: string;
    name: string;
    passwordhash: string;
    user_id: number;
}

type Post = {
    id: string;
    content: string;
    title: string;
    user_id: number;
    created_at: Date;
    user: User;

}


const Blog:FC<{}> = () => {
    const [posts, setPosts] = useState(Array<Post>);
    const loggedIn = useSelector((state: RootState) => state.userReducer.userName === 'Chris Dial' ? true : false);

    const navigate = useNavigate();

    const hydratePosts = () => {
        fetch(window.location.protocol + '//' + window.location.hostname + ':3000/blogs')
           .then((response) => response.json())
           .then((data) => {
              setPosts(data);
           })
           .catch((err) => {
              console.log(err.message);
           });
    };

    useEffect(() => {
        hydratePosts();
    }, []);


    return (
        <div className="Page-content">
            <Container>
                <Row>
                    <Col className="Page-header" sm={12} xs={12}>
                        {strings.blog.title}
                    </Col>
                </Row>

                {posts.map((post) => {
                    return (
                        <Row key={post.id}>
                            <Col>
                                <Card className="Page-card">
                                    <Card.Body>
                                        <Card.Title>{post.title}</Card.Title>
                                        <Card.Subtitle className="pt-2">{strings.blog.posted_on} {new Date(post.created_at).toLocaleDateString()}</Card.Subtitle>
                                        <Card.Subtitle className="pt-2">{strings.blog.posted_by} {post.user.name}</Card.Subtitle>
                                        <Card.Text className="pt-2">
                                            {post.content}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    );
                })}


                <Row className="my-3">
                    <Col sm={2}>
                    {loggedIn && (

                        <SubmitBlog hydratePosts={hydratePosts} isOpen={false} />
                        ) }
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default Blog;